import React, { useState, useContext } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import Select, { components } from "react-select";
import { Link, navigate } from "gatsby";
import Helmet from "react-helmet";
import Media from "react-media";

// Context
import WindowWidthContext from "../../components/context/window-width";

// Components
import StoreContext from "../../components/context/store";
import { MobileAccountNavigation } from "../../components/account/mobile-account-navigation";
import { DropdownIcon } from "../../components/icons/dropdown-icon";
import { AccountNavigation } from "../../components/account/account-navigation";
import Layout from "../../components/account/Layout";
import { Spacer } from "../../components/utils/spacer";

// Styles
import { secondaryDark } from "../../components/utils/colors";

import {
	Page,
	AccountContainer,
	ContentContainer,
	AddressFields,
	CountrySelector,
	AddressInputField,
} from "../../components/trade/trade-components";

const CUSTOMER_CREATE_ADDRESS = gql`
	mutation customerAddressCreate(
		$customerAccessToken: String!
		$address: MailingAddressInput!
	) {
		customerAddressCreate(
			customerAccessToken: $customerAccessToken
			address: $address
		) {
			customerAddress {
				id
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

const CUSTOMER_EDIT_DEFAULT_ADDRESS = gql`
	mutation customerDefaultAddressUpdate(
		$customerAccessToken: String!
		$addressId: ID!
	) {
		customerDefaultAddressUpdate(
			customerAccessToken: $customerAccessToken
			addressId: $addressId
		) {
			customer {
				id
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

const AddAddress = () => {
	const [firstNameInput, setFirstNameInput] = useState("");
	const [lastNameInput, setLastNameInput] = useState("");
	const [companyInput, setCompanyInput] = useState("");
	const [addressInput, setAddressInput] = useState("");
	const [apartmentInput, setApartmentInput] = useState("");
	const [cityInput, setCityInput] = useState("");
	const [countryInput, setCountryInput] = useState("");
	const [zipInput, setZipInput] = useState("");
	const [phoneInput, setPhoneInput] = useState("");
	const [checkDefaultAddress, setCheckDefaultAddress] = useState(false);

	const { customerAccessToken } = useContext(StoreContext);
	const windowWidth = useContext(WindowWidthContext);

	const DropdownIndicator = (props) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<DropdownIcon isOpen={props.selectProps.menuIsOpen} />
				</components.DropdownIndicator>
			)
		);
	};

	const options = [
		{ value: "262", label: "United Kingdom" },
		{ value: "286", label: "Aaland Islands" },
		{ value: "274", label: "Afghanistan" },
		{ value: "2", label: "Albania" },
		{ value: "3", label: "Algeria" },
		{ value: "178", label: "American Samoa" },
		{ value: "4", label: "Andorra" },
		{ value: "5", label: "Angola" },
		{ value: "176", label: "Anguilla" },
		{ value: "175", label: "Antigua And Barbuda" },
		{ value: "6", label: "Argentina" },
		{ value: "7", label: "Armenia" },
		{ value: "179", label: "Aruba" },
		{ value: "8", label: "Australia" },
		{ value: "9", label: "Austria" },
		{ value: "10", label: "Azerbaijan" },
		{ value: "11", label: "Bahamas" },
		{ value: "12", label: "Bahrain" },
		{ value: "13", label: "Bangladesh" },
		{ value: "14", label: "Barbados" },
		{ value: "15", label: "Belarus" },
		{ value: "16", label: "Belgium" },
		{ value: "17", label: "Belize" },
		{ value: "18", label: "Benin" },
		{ value: "19", label: "Bermuda" },
		{ value: "20", label: "Bhutan" },
		{ value: "21", label: "Bolivia" },
		{
			value: "325",
			label: "Bonaire, Saint Eustatius and Saba",
		},
		{ value: "22", label: "Bosnia and Herzegovina" },
		{ value: "23", label: "Botswana" },
		{ value: "181", label: "Bouvet Island" },
		{ value: "24", label: "Brazil" },
		{ value: "180", label: "Brunei Darussalam" },
		{ value: "25", label: "Bulgaria" },
		{ value: "26", label: "Burkina Faso" },
		{ value: "27", label: "Burundi" },
		{ value: "28", label: "Cambodia" },
		{ value: "29", label: "Cameroon" },
		{ value: "30", label: "Canada" },
		{ value: "31", label: "Cape Verde" },
		{ value: "32", label: "Cayman Islands" },
		{
			value: "33",
			label: "Central African Republic",
		},
		{ value: "34", label: "Chad" },
		{ value: "35", label: "Chile" },
		{ value: "36", label: "China" },
		{ value: "185", label: "Christmas Island" },
		{ value: "37", label: "Colombia" },
		{ value: "204", label: "Comoros" },
		{ value: "38", label: "Congo" },
		{ value: "183", label: "Cook Islands" },
		{ value: "268", label: "Costa Rica" },
		{ value: "275", label: "Cote D'Ivoire" },
		{ value: "40", label: "Croatia" },
		{ value: "276", label: "Cuba" },
		{ value: "298", label: "Curacao" },
		{ value: "41", label: "Cyprus" },
		{ value: "42", label: "Czech Republic" },
		{
			value: "318",
			label: "Democratic Republic of the Congo",
		},
		{ value: "43", label: "Denmark" },
		{ value: "44", label: "Djibouti" },
		{ value: "289", label: "Dominica" },
		{ value: "187", label: "Dominican Republic" },
		{ value: "45", label: "Ecuador" },
		{ value: "46", label: "Egypt" },
		{ value: "47", label: "El Salvador" },
		{ value: "48", label: "Equatorial Guinea" },
		{ value: "49", label: "Eritrea" },
		{ value: "50", label: "Estonia" },
		{ value: "51", label: "Ethiopia" },
		{ value: "189", label: "Falkland Islands" },
		{ value: "191", label: "Faroe Islands" },
		{ value: "52", label: "Fiji" },
		{ value: "53", label: "Finland" },
		{ value: "54", label: "France" },
		{ value: "193", label: "French Guiana" },
		{ value: "277", label: "French Polynesia" },
		{ value: "56", label: "Gabon" },
		{ value: "57", label: "Gambia" },
		{ value: "58", label: "Georgia" },
		{ value: "59", label: "Germany" },
		{ value: "60", label: "Ghana" },
		{ value: "194", label: "Gibraltar" },
		{ value: "61", label: "Greece" },
		{ value: "195", label: "Greenland" },
		{ value: "192", label: "Grenada" },
		{ value: "196", label: "Guadeloupe" },
		{ value: "62", label: "Guam" },
		{ value: "198", label: "Guatemala" },
		{ value: "270", label: "Guernsey" },
		{ value: "63", label: "Guinea" },
		{ value: "65", label: "Guyana" },
		{ value: "200", label: "Haiti" },
		{ value: "66", label: "Honduras" },
		{ value: "67", label: "Hong Kong" },
		{ value: "68", label: "Hungary" },
		{ value: "69", label: "Iceland" },
		{ value: "70", label: "India" },
		{ value: "71", label: "Indonesia" },
		{ value: "278", label: "Iran" },
		{ value: "279", label: "Iraq" },
		{ value: "74", label: "Ireland" },
		{ value: "323", label: "Isle of Man" },
		{ value: "75", label: "Israel" },
		{ value: "76", label: "Italy" },
		{ value: "202", label: "Jamaica" },
		{ value: "78", label: "Japan" },
		{
			value: "288",
			label: "Jersey (Channel Islands)",
		},
		{ value: "79", label: "Jordan" },
		{ value: "80", label: "Kazakhstan" },
		{ value: "81", label: "Kenya" },
		{ value: "203", label: "Kiribati" },
		{ value: "82", label: "Kuwait" },
		{ value: "83", label: "Kyrgyzstan" },
		{
			value: "84",
			label: "Lao People's Democratic Republic",
		},
		{ value: "85", label: "Latvia" },
		{ value: "86", label: "Lebanon" },
		{ value: "87", label: "Lesotho" },
		{ value: "88", label: "Liberia" },
		{ value: "281", label: "Libya" },
		{ value: "90", label: "Liechtenstein" },
		{ value: "91", label: "Lithuania" },
		{ value: "92", label: "Luxembourg" },
		{ value: "208", label: "Macau" },
		{ value: "93", label: "Macedonia" },
		{ value: "94", label: "Madagascar" },
		{ value: "95", label: "Malawi" },
		{ value: "96", label: "Malaysia" },
		{ value: "97", label: "Maldives" },
		{ value: "98", label: "Mali" },
		{ value: "99", label: "Malta" },
		{ value: "207", label: "Marshall Islands" },
		{ value: "210", label: "Martinique" },
		{ value: "100", label: "Mauritania" },
		{ value: "212", label: "Mauritius" },
		{ value: "241", label: "Mayotte" },
		{ value: "101", label: "Mexico" },
		{ value: "102", label: "Moldova, Republic of" },
		{ value: "103", label: "Monaco" },
		{ value: "104", label: "Mongolia" },
		{ value: "290", label: "Montenegro" },
		{ value: "294", label: "Montserrat" },
		{ value: "105", label: "Morocco" },
		{ value: "106", label: "Mozambique" },
		{ value: "242", label: "Myanmar" },
		{ value: "107", label: "Namibia" },
		{ value: "108", label: "Nepal" },
		{ value: "109", label: "Netherlands" },
		{ value: "110", label: "Netherlands Antilles" },
		{ value: "213", label: "New Caledonia" },
		{ value: "111", label: "New Zealand" },
		{ value: "112", label: "Nicaragua" },
		{ value: "113", label: "Niger" },
		{ value: "114", label: "Nigeria" },
		{ value: "217", label: "Niue" },
		{ value: "214", label: "Norfolk Island" },
		{ value: "272", label: "North Korea" },
		{ value: "116", label: "Norway" },
		{ value: "117", label: "Oman" },
		{ value: "118", label: "Pakistan" },
		{ value: "222", label: "Palau" },
		{ value: "282", label: "Palestine" },
		{ value: "119", label: "Panama" },
		{ value: "219", label: "Papua New Guinea" },
		{ value: "120", label: "Paraguay" },
		{ value: "121", label: "Peru" },
		{ value: "122", label: "Philippines" },
		{ value: "221", label: "Pitcairn" },
		{ value: "123", label: "Poland" },
		{ value: "124", label: "Portugal" },
		{ value: "126", label: "Qatar" },
		{ value: "315", label: "Republic of Kosovo" },
		{ value: "127", label: "Reunion" },
		{ value: "128", label: "Romania" },
		{ value: "129", label: "Russia" },
		{ value: "130", label: "Rwanda" },
		{ value: "205", label: "Saint Kitts and Nevis" },
		{ value: "206", label: "Saint Lucia" },
		{ value: "324", label: "Saint Martin" },
		{
			value: "237",
			label: "Saint Vincent and the Grenadines",
		},
		{ value: "132", label: "Samoa (Independent)" },
		{ value: "227", label: "San Marino" },
		{ value: "255", label: "Sao Tome and Principe" },
		{ value: "133", label: "Saudi Arabia" },
		{ value: "134", label: "Senegal" },
		{ value: "326", label: "Serbia" },
		{ value: "135", label: "Seychelles" },
		{ value: "136", label: "Sierra Leone" },
		{ value: "137", label: "Singapore" },
		{ value: "302", label: "Sint Maarten" },
		{ value: "138", label: "Slovakia" },
		{ value: "139", label: "Slovenia" },
		{ value: "223", label: "Solomon Islands" },
		{ value: "140", label: "Somalia" },
		{ value: "141", label: "South Africa" },
		{
			value: "257",
			label: "South Georgia and the South Sandwich Islands",
		},
		{ value: "142", label: "South Korea" },
		{ value: "311", label: "South Sudan" },
		{ value: "143", label: "Spain" },
		{ value: "144", label: "Sri Lanka" },
		{ value: "293", label: "Sudan" },
		{ value: "146", label: "Suriname" },
		{
			value: "225",
			label: "Svalbard and Jan Mayen Islands",
		},
		{ value: "147", label: "Swaziland" },
		{ value: "148", label: "Sweden" },
		{ value: "149", label: "Switzerland" },
		{ value: "285", label: "Syria" },
		{ value: "152", label: "Taiwan" },
		{ value: "260", label: "Tajikistan" },
		{ value: "153", label: "Tanzania" },
		{ value: "154", label: "Thailand" },
		{ value: "233", label: "Timor-Leste" },
		{ value: "155", label: "Togo" },
		{ value: "232", label: "Tonga" },
		{ value: "234", label: "Trinidad and Tobago" },
		{ value: "156", label: "Tunisia" },
		{ value: "157", label: "Turkey" },
		{ value: "158", label: "Turkmenistan" },
		{
			value: "287",
			label: "Turks &amp; Caicos Islands",
		},
		{ value: "159", label: "Uganda" },
		{ value: "161", label: "Ukraine" },
		{ value: "162", label: "United Arab Emirates" },
		{ value: "164", label: "USA" },
		{ value: "163", label: "Uruguay" },
		{ value: "165", label: "Uzbekistan" },
		{ value: "239", label: "Vanuatu" },
		{
			value: "166",
			label: "Vatican City State (Holy See)",
		},
		{ value: "167", label: "Venezuela" },
		{ value: "168", label: "Vietnam" },
		{
			value: "169",
			label: "Virgin Islands (British)",
		},
		{ value: "238", label: "Virgin Islands (U.S.)" },
		{ value: "188", label: "Western Sahara" },
		{ value: "170", label: "Yemen" },
		{ value: "173", label: "Zambia" },
		{ value: "174", label: "Zimbabwe" },
	];

	return (
		<Layout>
			<Helmet title={`Add Address | Christian Watson`} />
			<Page>
				<Media
					queries={{
						medium: "(min-width: 1025px)",
					}}
					defaultMatches={{
						medium: windowWidth === 1025,
					}}
					render={() => <AccountNavigation position={`relative`} top={0} />}
				/>

				<Media
					queries={{
						medium: "(max-width: 1024px)",
					}}
					defaultMatches={{
						medium: windowWidth === 1024,
					}}
					render={() => (
						<MobileAccountNavigation position={`relative`} top={0} />
					)}
				/>

				<AccountContainer>
					<ContentContainer>
						<div className="text-column">
							<Mutation mutation={CUSTOMER_CREATE_ADDRESS}>
								{(customerAddressCreate) => {
									return (
										<Mutation mutation={CUSTOMER_EDIT_DEFAULT_ADDRESS}>
											{(customerDefaultAddressUpdate) => {
												return (
													<AddressFields>
														<div className="form-header">
															<h1 className="caps">Add A New Address</h1>
														</div>

														<AddressInputField
															desktopWidth={`calc(50% - 20px)`}
															className="input"
															value={firstNameInput}
															type="text"
															onChange={(e) =>
																setFirstNameInput(e.target.value)
															}
															placeholder="First Name"
															required
														/>

														<AddressInputField
															desktopWidth={`calc(50% - 20px)`}
															className="input"
															value={lastNameInput}
															type="text"
															onChange={(e) => setLastNameInput(e.target.value)}
															placeholder="Last Name"
															required
														/>

														<AddressInputField
															className="input"
															value={companyInput}
															type="text"
															onChange={(e) => setCompanyInput(e.target.value)}
															placeholder="Company Name"
														/>

														<AddressInputField
															className="input"
															value={addressInput}
															type="text"
															onChange={(e) => setAddressInput(e.target.value)}
															placeholder="Street address"
														/>

														<AddressInputField
															className="input"
															value={apartmentInput}
															type="text"
															onChange={(e) =>
																setApartmentInput(e.target.value)
															}
															placeholder="Apartment, suite, etc."
														/>

														<AddressInputField
															className="input"
															value={cityInput}
															type="text"
															onChange={(e) => setCityInput(e.target.value)}
															placeholder="City"
														/>

														<CountrySelector>
															<Select
																options={options}
																className="country-dropdown"
																classNamePrefix="dropdown"
																placeholder={"Country"}
																onChange={(e) => setCountryInput(e.label)}
																components={{ DropdownIcon }}
															/>
														</CountrySelector>

														<AddressInputField
															desktopWidth={`calc(50% - 20px)`}
															className="input"
															value={zipInput}
															type="text"
															onChange={(e) => setZipInput(e.target.value)}
															placeholder="Postal Code"
															required
														/>

														<AddressInputField
															desktopWidth={`calc(50% - 20px)`}
															className="input"
															value={phoneInput}
															type="text"
															onChange={(e) => setPhoneInput(e.target.value)}
															placeholder="Phone"
														/>

														<div className="form-footer">
															<div className="button-container">
																<button
																	onClick={(e) => {
																		e.preventDefault();
																		customerAddressCreate({
																			variables: {
																				customerAccessToken:
																					customerAccessToken.accessToken,
																				address: {
																					address1: addressInput,
																					city: cityInput,
																					company: companyInput,
																					country: countryInput,
																					firstName: firstNameInput,
																					lastName: lastNameInput,
																					phone: phoneInput,
																					zip: zipInput,
																				},
																			},
																		}).then((result) => {
																			navigate(`/account/addresses/`);
																		});
																	}}
																>
																	Save Address
																</button>

																<Link
																	to={`/account/addresses`}
																	className="link"
																>
																	Cancel
																</Link>
															</div>
														</div>
													</AddressFields>
												);
											}}
										</Mutation>
									);
								}}
							</Mutation>
						</div>
					</ContentContainer>
				</AccountContainer>
			</Page>
			<Spacer />
		</Layout>
	);
};

export default AddAddress;
